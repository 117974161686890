<template>
    <div class="loading-view">
        <span class="loading-view__svg"><span><span></span></span></span>
    </div>
</template>

<script>
    export default {
        name: 'LoadingView'
    }
</script>

<style lang="scss" scoped>
    @import '../assets/css/base';

    @-webkit-keyframes
    rotating{from{-webkit-transform:rotate(0deg)}to{-webkit-transform:rotate(360deg)}}
    @keyframes
    rotating{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}

    .loading-view {
        width: 100%;
        height: 300px;
        background: #fff;
        background: rgba(255, 255, 255, .5);
        -webkit-transition: opacity .2s ease-in, visibility .2s ease-in;
        transition: opacity .2s ease-in, visibility .2s ease-in;

        &__svg {

            >span {
                display: block;
                height: 80px;
                width: 80px;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-left: -40px;
                margin-top: -40px;
                background: #fff;
                box-shadow: 0 4px 11px 0 rgb(0 0 0 / 10%);
                border-radius: 50%;

                >span {
                    display: block;
                    height: 80px;
                    width: 80px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: url('../assets/images/spinner.svg') no-repeat center;
                    -webkit-animation: rotating 2s linear infinite;
                    animation: rotating 2s linear infinite;
                    border-radius: 50%;
                }
            }
        }
    }
</style>