<template>
    <div class="product-collection">
        <h2 class="product-collection__title">{{ layout.title }}</h2>
        <!--<div class="product-collection__desc">-->
            <!--{{ layout['time_from'] + 12*60*60 | moment("D MMMM") }} - {{ weekDeal['time_to'] - 12*60*60 | moment("D MMMM") }}-->
        <!--</div>-->
        <div class="product-collection__slider slick-white"
             @wheel="onCarouselWheel">
            <VueSlickCarousel
                    :arrows="true"
                    :dots="false"
                    :slidesToScroll="1"
                    :slidesToShow="1"
                    :variableWidth="true"
                    :swipeToSlide="true"
                    :infinite="true"
                    :touchMove="false"
                    ref="carousel">
                {% for product in weekDeal['products'] %}
                    <div v-for="product in layout.products"
                         class="product-collection__item">
                            <ProductCard
                                    :needLazyPhoto="false"
                                    :product="product"/>
                    </div>
                {% endfor %}
            </VueSlickCarousel>
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import ProductCard from '@/components/ProductCard.vue'

    export default {
        name: 'LayoutProductCollection',
        components: {
            VueSlickCarousel,
            ProductCard
        },
        props: ['layout'],
        methods: {
            onCarouselWheel(e) {
                console.log(e);

                if (e.deltaX) {
                    e.preventDefault();
                }

                if (e.deltaX < 0 || e.deltaY < 0) {
                    this.$refs.carousel.prev()
                } else {
                    this.$refs.carousel.next()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .product-collection {
        padding: 15px;

        &__title {
            font-size: 20px;
            margin: 5px;
        }

        &__desc {
            font-size: 16px;
            color: #777;
            margin-bottom: 2px;
        }

        &__slider {
            margin-top: 15px;
        }

        &__item {
            margin-left: 5px;
            margin-right: 5px;
        }

        /*&__slider {*/
            /*height: 200px;*/
            /*overflow-y: hidden;*/

            /*.slick-list {*/
                /*overflow-y: hidden;*/
            /*}*/
        /*}*/
    }
</style>