<template>
    <div class="cart"
         :class="{'cart--checkout': $route.name === 'Checkout'}">
        <div
                :class="{'cart__content--checkout': $route.name === 'Checkout'}"
                class="cart__content">

            <div class="cart__products">
                <h2 class="cart__title">Моята поръчка
                    <b-button v-if="cart !== null && cart.count > 0" @click="$bvModal.show('modalCartClear')" class="cart__clear" variant="link" v-b-tooltip.hover.left title="Изтрий всичко">
                        <i class="material-icons">delete</i>
                    </b-button>
                </h2>

                <template v-if="cart !== null && cart.count > 0">
                    <div v-for="product in cart.products">
                        <CartProduct
                                :product="product"/>
                    </div>
                </template>

                <template v-else>
                    <div :class="{ 'cart__empty--checkout': $route.name === 'Checkout'}" class="cart__empty"><span>🛒</span><br><br>Изберете продукти и <br>ги добавете в количката</div>
                </template>

                <div class="cart__loading loading" v-bind:class="{ 'hidden': !loading }">
                    <div class="loading__progress">
                        <div class="loading__dot"></div>
                        <div class="loading__dot"></div>
                        <div class="loading__dot"></div>
                    </div>
                    <div class="loading__text">Актуализация на кошницата..</div>
                </div>
            </div>

            <div v-if="$route.name !== 'Checkout'" class="cart__info">
                <div class="cart__info-content">
                    <div v-on:click="openAddressModal()" class="cart__info-block cart__info-block--time">
                        <div class="cart__info-title">Време за доставка</div>
                        <!--<div class="cart__info-desc">Утре 21:00 <i class="material-icons">edit</i></div>-->
                        <div v-if="cart != null" class="cart__info-desc">{{ cart.slot.text }}</div>
                    </div>
                    <div class="cart__info-block cart__info-block--price">
                        <div class="cart__info-title">Общо</div>
                        <div class="cart__info-desc"><template v-if="cart !== null">{{ cart.price.without_delivery }}</template><template v-else>0</template> лв.</div>
                        <div class="cart__info-delivery">+ доставка <template v-if="cart !== null">({{ cart.delivery.price_sum.special }} лв.)</template></div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>

        <template v-if="$route.name !== 'Checkout'">
            <template v-if="config != null && (cart === null || cart.price.special < config.minimumOrder)">
                <a href="#" class="cart__btn cart__btn--disabled btn btn-lg btn-tilda disabled">Минималната сума на поръчка е {{ config.minimumOrder }} лв.</a>
            </template>
            <template v-else>
                <a href="/checkout" v-on:click.prevent="checkout()" class="cart__btn btn btn-lg btn-tilda">
                    Оформи поръчката<br>
                    <template v-if="config != null">
                        <span v-if="cart.delivery.price_sum.special !== '0.00'">Безплатна доставка при поръчка над {{ config.minimumDeliveryFree }} лв.</span>
                        <span v-else>Безплатна доставка</span>
                    </template>
                </a>
            </template>
        </template>

        <b-modal
                ref="modalCartClear"
                id="modalCartClear"
                modal-class="cart-modal"
                title="Потвърждение"
                hide-footer
                centered>
            <p>Сигурни ли сте, че искате да изтриете всичките продукти в количката?</p>
            <br>
            <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-5">
                    <b-button variant="primary" class="btn-tilda" block @click="clearCart()">Да</b-button>
                </div>
                <div class="col-sm-5">
                    <b-button variant="light" block @click="$bvModal.hide('modalCartClear')">Не</b-button>
                </div>
                <div class="col-sm-1"></div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import router from '@/router'
    import CartProduct from "./CartProduct";

    export default {
        name: 'Cart',
        components: {
            CartProduct
        },
        computed: {
            cart() {
                return this.$store.state.cart.order
            },
            loading() {
                return this.$store.state.cart.cartChanged === null
            },
            config() {
                return this.$store.state.cart.config
            }
        },
        methods: {
            ...mapActions([
                'cartClear'
            ]),

            checkout() {
                if (!this.$store.state.auth.isUserTokenSet) {

                    this.$parent.$parent.$refs.authModal.open({
                        toCheckout: this.cart != null && this.cart.address != null
                    });

                } else if (this.cart != null && this.cart.address == null) {

                    this.$parent.$parent.isAddressModalOpen = true;
                    this.$parent.$parent.isAddressModalToCheckout = true;

                } else if (this.cart != null) {
                    window.scrollTo(0,0);

                    router.push({
                        name: 'Checkout'
                    })
                }
            },
            openAddressModal() {
                this.$parent.$parent.isAddressModalOpen = true;
            },
            async clearCart() {
                this.$bvModal.hide('modalCartClear');
                await this.cartClear()
            }
        }
    }
</script>

<style lang="scss">
    .cart-modal {
        .modal-dialog {
            width: 500px;
        }

        .col-sm-6 {
            text-align: center;
        }
    }
</style>

<style lang="scss" scoped>
    .cart {
        width: 100%;
        height: 100%;
        overflow: hidden;

        &--checkout {
            height: auto;
        }

        &__content {
            border: 2px solid #ffd300;
            border-radius: 8px;
            background-color: #ffffff;
            margin-top: 10px;
            height: calc(100% - 80px);
            position: relative;
            overflow: hidden;

            &--checkout {
                border: 0;
                height: auto;
            }
        }

        &__btn {
            width: 100%;
            height: 50px;
            margin-top: 10px;
            line-height: 18px;
            padding-top: 6px;

            &:focus, &:active {
                color: #fff;
            }

            &--disabled {
                font-size: 15px;
                line-height: 18px;
                padding-left: 55px;
                padding-right: 55px;
                padding-top: 7px;
            }

            span {
                font-size: 12px;
            }
        }

        &__title {
            font-size: 20px;
            padding: 20px 20px 10px;
            font-weight: 700;
            background: #fff;
            /*position: absolute;*/
            /*top: 0;*/
            /*left: 0;*/
            /*width: 100%;*/
            /*z-index: 2;*/
            border-radius: 8px 8px 0 0;
        }

        &__clear {
            padding: 0;
            float: right;
            font-weight: 400;
            color: #dddddd;

            i {
                font-size: 20px;
            }
        }

        &__products {
            overflow: auto;
            height: calc(100% - 67px);
            padding-bottom: 10px;
        }

        &__info {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 10px 20px 15px 20px;
            border-top: 1px solid #dedede;
            border-radius: 0 0 8px 8px;
            background: #fff;

            &-content {

            }

            &-title {
                color: #b0b0b0;
                font-size: 14px;
                margin-bottom: 2px;
            }

            &-desc {
                font-size: 15px;

                i {
                    font-size: 18px;
                    position: relative;
                    top: 2px;
                }
            }

            &-block {
                float: left;

                &--time {
                    width: calc(100% - 100px);
                    padding-right: 20px;
                    cursor: pointer;
                }

                &--price {
                    width: 100px;
                }
            }

            &-delivery {
                position: absolute;
                bottom: 1px;
                font-size: 11px;
                opacity: 0.6;
            }
        }

        &__empty {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 40%;
            text-align: center;
            color: #000;
            font-weight: 400;

            &--checkout {
                position: relative;
                margin-top: 40px;
                margin-bottom: 0;
            }

            span {
                font-size: 40px;
            }
        }

        &__loading {

            .loading__progress {
                left: 35px;
            }

            .loading__text {
                position: absolute;
                left: 65px;
                font-size: 16px;
                font-weight: 300;
                top: 9px;
            }
        }
    }
</style>