<template>
    <div class="catalog">
        <div class="container-fluid">
            <div class="catalog__categories-container">
                <h2 class="catalog__title">Категории</h2>
                <div class="catalog__categories">
                    <router-link
                            :to="{ name: 'Catalog' }"
                            :key="0"
                            :class="{'categories__item--active': $route.params.categorySlug === undefined}"
                            class="categories__item">
                        <div class="categories__image" style="background-image: url('https://api.hladilnik.efimenko.eu/files/catalog/categories/2.jpg?1617539944')"></div>
                        <div class="categories__title">Промоции</div>
                    </router-link>
                    <router-link
                            v-for="category in categories"
                            :key="category.id"
                            :to="{ name: 'CatalogCategory', params: { categorySlug: category.slug } }"
                            :class="{'categories__item--active': $route.params.categorySlug === category.slug}"
                            class="categories__item">
                        <div class="categories__image" :style="{'background-image': 'url(' + category.photo_url + ')'}"></div>
                        <div class="categories__title">{{ category.title }}</div>
                    </router-link>
                </div>
                <br>
            </div>
            <div class="catalog__cart">
                <Cart />
            </div>
            <div class="catalog__content">
                <LoadingView v-if="layout == null"/>

                <div v-for="l in layout">
                    <LayoutProductGrid
                            :ref="'category' + l.category.id"
                            v-if="l.view === 'PRODUCT_GRID'"
                            :layout="l"/>
                    <LayoutProductCollection
                            v-else-if="l.view === 'PRODUCT_COLLECTION'"
                            :layout="l"/>
                    <LayoutCategoryCollection
                            v-else-if="l.view === 'CATEGORY_COLLECTION'"
                            :layout="l"/>
                    <LayoutHeader
                            v-else-if="l.view === 'HEADER'"
                            :layout="l"/>
                    <div v-else-if="l.view === 'CATEGORY_BADGES'">
                        <div class="catalog__badges">
                            <b-button v-for="l in layout"
                                      v-if="l.view === 'PRODUCT_GRID'"
                                      pill
                                      size="sm"
                                      variant="light"
                                      @click="scrollToElement('category' + l.category.id)">{{ l.category.title }}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
    import Cart from '@/components/Cart.vue'
    import LoadingView from '@/components/LoadingView.vue'
    import LayoutProductGrid from '@/views/catalog/LayoutProductGrid.vue'
    import LayoutProductCollection from '@/views/catalog/LayoutProductCollection.vue'
    import LayoutCategoryCollection from '@/views/catalog/LayoutCategoryCollection.vue'
    import LayoutHeader from '@/views/catalog/LayoutHeader.vue'

    import { mapActions } from 'vuex'

    export default {
        name: 'Catalog',
        components: {
            Cart,
            LoadingView,
            LayoutProductGrid,
            LayoutProductCollection,
            LayoutCategoryCollection,
            LayoutHeader
        },
        computed: {
            categories() {
                return this.$store.state.catalog.menu
            },
            layout() {
                return this.$store.state.catalog.layout
            }
        },
        methods: {
            ...mapActions([
                'catalogMenu',
                'catalogLayout',
                'cartGet'
            ]),
            async getCatalogMenu(params) {
                await this.catalogMenu(params)
            },
            async getCatalogLayout(params) {
                await this.catalogLayout(params)
            },
            async getCart() {
                await  this.cartGet()
            },
            scrollToElement(el) {
                const v = this.$refs[el][0];
                v.$el.scrollIntoView({ behavior: "smooth" });
            },
        },
        created() {
            this.getCart();

            this.getCatalogMenu({
                menu: true,
                layout: this.$route.params.categorySlug === undefined && this.$route.params.promotionSlug === undefined,
            });

            if (this.$route.params.categorySlug !== undefined) {
                this.getCatalogLayout({
                    id: this.$route.params.categorySlug,
                    params: {
                        object: 'CATEGORY'
                    }
                });
            } else if (this.$route.params.promotionSlug !== undefined) {
                this.getCatalogLayout({
                    id: this.$route.params.promotionSlug,
                    params: {
                        object: 'PROMOTION'
                    }
                });
            }
        },
        watch: {
            $route() {
                if (this.$route.params.categorySlug !== undefined) {
                    this.getCatalogLayout({
                        id: this.$route.params.categorySlug,
                        params: {
                            object: 'CATEGORY'
                        }
                    });
                } else if (this.$route.params.promotionSlug !== undefined) {
                    this.getCatalogLayout({
                        id: this.$route.params.promotionSlug,
                        params: {
                            object: 'PROMOTION'
                        }
                    });
                } else {
                    this.getCatalogMenu({
                        menu: false,
                        layout: true
                    });
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/css/base.scss";

    .catalog {
        margin-top: 75px;

        &__title {
            font-size: 16px;
            margin: 15px 0 0 0;
            font-weight: 700;
        }

        &__content {
            width: calc(100% - 640px);
            margin-left: 300px;
        }

        &__cart {
            position: fixed;
            top: 78px;
            right: 30px;
            width: 320px;
            height: calc(100vh - 78px);
        }

        &__categories {
            border: 2px solid #4683e91c;
            border-radius: 4px;
            background: #ffffff;
            margin-top: 10px;
            margin-bottom: 10px;
            padding-bottom: 5px;
            padding-top: 5px;

            &-container {
                width: 280px;
                height: 100%;
                position: absolute;
                top: 70px;
            }
        }

        &__badges {
            margin-left: 15px;
            margin-right: 15px;
            padding-top: 15px;

            .btn {
                margin-right: 10px;
                margin-bottom: 10px;
            }
        }

        .categories {

            &__item {
                padding: 10px 20px;
                font-size: 14px;
                color: #222;
                clear: both;
                height: 50px;
                width: 100%;
                display: flex;
                align-items: center;

                &:focus {
                    color: #222;
                }

                &:hover {
                    color: $tilda !important;
                }

                &--active {
                    background-color: #4683e91c;
                    color: $tilda !important;
                }
            }

            &__image {
                width: 30px;
                height: 30px;
                border-radius: 3px;
                background-size: cover;
                float: left;
            }

            &__title {
                float: left;
                width: calc(100% - 42px);
                margin-left: 12px;
            }
        }
    }
</style>