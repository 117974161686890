<template>
    <div class="category-collection">
        <h2 class="category-collection__title">{{ layout.title }}</h2>
        <!--<div class="category-collection__desc">-->
        <!--{{ layout['time_from'] + 12*60*60 | moment("D MMMM") }} - {{ weekDeal['time_to'] - 12*60*60 | moment("D MMMM") }}-->
        <!--</div>-->
        <div class="category-collection__slider slick-white"
             @wheel="onCarouselWheel">
            <VueSlickCarousel
                    :arrows="true"
                    :dots="false"
                    :slidesToScroll="1"
                    :slidesToShow="1"
                    :variableWidth="true"
                    :swipeToSlide="true"
                    :infinite="true"
                    ref="carousel">
                {% for product in weekDeal['products'] %}
                    <div v-for="category in layout.categories"
                         class="category-collection__item">

                        <div class="category-collection-item">
                            <router-link
                                    :to="{ name: 'CatalogPromotion', params: { promotionSlug: category.slug } }">
                                <img class="category-collection-item__img" :src="category.photo_url">
                                <div class="category-collection-item__bg"></div>
                                <div class="category-collection-item__title">{{ category.title }}</div>
                            </router-link>
                        </div>
                    </div>
                {% endfor %}
            </VueSlickCarousel>
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'

    export default {
        name: 'LayoutCategoryCollection',
        components: {
            VueSlickCarousel
        },
        props: ['layout'],
        methods: {
            onCarouselWheel(e) {
                console.log(e);

                if (e.deltaX) {
                    e.preventDefault();
                }

                if (e.deltaX < 0 || e.deltaY < 0) {
                    this.$refs.carousel.prev()
                } else {
                    this.$refs.carousel.next()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .category-collection {
        padding: 15px;

        &__title {
            font-size: 24px;
            margin: 0 15px 0 0;
        }

        &__desc {
            font-size: 16px;
            color: #777;
            margin-bottom: 2px;
        }

        &__slider {
            margin-top: 15px;
        }

        &__item {
            margin-left: 5px;
            margin-right: 5px;
        }

        /*&__slider {*/
        /*height: 200px;*/
        /*overflow-y: hidden;*/

        /*.slick-list {*/
        /*overflow-y: hidden;*/
        /*}*/
        /*}*/
    }

    .category-collection-item {
        width: 300px;
        height: 180px;
        position: relative;
        overflow: hidden;
        border-radius: 5px;

        &__img {
            width: 100%;
            z-index: 1;
        }

        &__title {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 20px;
            color: #fff;
            font-weight: 700;
            z-index: 3;
            font-size: 20px;
        }

        &__bg {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.6));
        }
    }
</style>