<template>
    <div class="product-grid">
        <h2 class="product-grid__title">{{ layout.category.title }}</h2>
        <div class="product-grid__products">
            <div v-for="product in layout.products"
                 class="product-grid__item">

                <ProductCard
                    :product="product"/>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
    import ProductCard from '@/components/ProductCard.vue'

    export default {
        name: 'LayoutProductGrid',
        components: {
            ProductCard
        },
        props: ['layout']
    }
</script>

<style lang="scss" scoped>
    .product-grid {
        padding-top: 10px;

        &__title {
            font-size: 24px;
            margin: 0 15px 0;
        }

        &__products {
            margin-bottom: 15px;
            min-height: 290px;
        }

        &__item {
            float: left;
            width: calc(100% / 3);
            padding: 15px 15px;
        }
    }
</style> 