<template>
    <div class="catalog-header">
        <div class="catalog-header__photo" :style="{ 'background-image': 'url(' + layout.photo + ')' }"></div>
        <div class="catalog-header__bg"></div>
        <div class="catalog-header__title">{{ layout.title }}</div>
    </div>
</template>

<script>
    export default {
        name: 'LayoutHeader',
        props: ['layout']
    }
</script>

<style lang="scss" scoped>
    .catalog-header {
        margin: 15px 15px 25px;
        top: 20px;
        height: 200px;
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        &__photo {
            width: 100%;
            height: 100%;
            z-index: 1;
            background: no-repeat center;
            background-size: cover;
        }

        &__title {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 20px;
            color: #fff;
            font-weight: 700;
            z-index: 3;
            font-size: 20px;
        }

        &__bg {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.6));
        }
    }
</style>