<template xmlns:v-lazy="http://www.w3.org/1999/xhtml">
    <div class="product-card">
        <template v-if="needLazyPhoto == null || needLazyPhoto">
            <div class="product-card__photo" v-lazy:background-image="product.photo.url">
                <div class="product-card__badges">
                    <template v-if="product.price.normal !== product.price.special"><span class="product-card__badge product-card__badge--discount">-{{ Math.round(((product.price.normal - product.price.special) / product.price.normal) * 100) }}%</span></template>
                    <template v-if="product.is_hit === 1"><span class="product-card__badge product-card__badge--hit">Бестселър</span></template>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="product-card__photo" :style="{ 'background-image': 'url(' + product.photo.url + ')' }">
                <div class="product-card__badges">
                    <template v-if="product.price.normal !== product.price.special"><span class="product-card__badge product-card__badge--discount">-{{ Math.round(((product.price.normal - product.price.special) / product.price.normal) * 100) }}%</span></template>
                    <template v-if="product.is_hit === 1"><span class="product-card__badge product-card__badge--hit">Бестселър</span></template>
                </div>
            </div>
        </template>

        <template v-if="cart != null && cart.address != null">
            <template v-if="cart.slot.next_days === 0 && product.stock.quantity === 0"><div class="product-card__quantity product-card__quantity--orange">Може да поръчате за утре</div></template>
            <template v-else-if="product.stock.quantity > 0"><div class="product-card__quantity">В наличност <template v-if="product.stock.quantity > 100">много</template><template v-else>{{ product.stock.quantity }} бр.</template></div></template>
            <template v-else><div class="product-card__quantity product-card__quantity--red">Няма наличност</div></template>
        </template>

        <div class="product-card__title">
            {{ product.title }} <span class="product-card__weight">{{ product.weight.text }}</span>
            <span @click="changeCart(true)" class="product-card__link-area"></span>
        </div>

        <div v-if="product.price.normal !== product.price.special" class="product-card__price">
            <span class="product-card__price-normal product-card__price-normal--red">{{ product.price.special }}&thinsp;лв.</span>
            <div class="product-card__price-discount">{{ product.price.normal }}&thinsp;лв.</div>
        </div>
        <div v-else class="product-card__price">
            <span class="product-card__price-normal">{{ product.price.normal }}&thinsp;лв.</span>
        </div>
        <div class="product-card__bottom">
            <template v-if="loading">
                <div class="product-card__loader">
                    <div class="product-card__spinner"></div>
                </div>
            </template>
            <template v-if="productCart != null">
                <div class="product-card__basket">
                    <div v-on:click="changeCart(false)" class="product-card__basket-minus"></div>
                    <div class="product-card__basket-value noselect"><template v-if="!loading">{{ quantity }} бр</template><template v-else>&nbsp;</template></div>
                    <div v-on:click="changeCart(true)" class="product-card__basket-plus"></div>
                </div>
            </template>
            <template v-else>
                <template v-if="product.stock.quantity > 0">
                    <button v-on:click="changeCart(true)" class="product-card__btn btn btn-sm btn-tilda">
                        <template v-if="!loading">
                            <svg width="12" height="12">
                                <use xlink:href="@/assets/images/icons.svg?v=72#icon-basket"></use>
                            </svg> В количката
                        </template>
                    </button>
                </template>
                <template v-else>
                    <button v-on:click="wantMore()" class="product-card__btn product-card__btn--more btn btn-outline-tilda btn-sm">
                        <template v-if="!loading">Искам повече</template>
                    </button>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'ProductCard',
        props: ['product', 'needLazyPhoto'],
        data: function () {
            return {
                quantity: 0,
                loading: false
            }
        },
        computed: {
            cart() {
                return this.$store.state.cart.order
            },
            cartChanged() {
                return this.$store.state.cart.cartChanged === null
            },
            cartMessage() {
                return this.$store.state.cart.cartMessage
            },
            productCart() {
                this.quantity = 0;
                let productCart = null;
                if (this.$store.state.cart.order != null) {
                    for (const p of this.$store.state.cart.order.products) {
                        if (p.id === this.product.id) {
                            productCart = p;
                            this.quantity = p.quantity;
                            break;
                        }
                    }
                }
                return productCart;
            }
        },
        methods: {
            ...mapActions([
                'cartProduct',
                'cartProductLoading',
                'cartMessageClear',
                'cartWantMore'
            ]),
            async changeCart(isAdd) {
                await this.cartProductLoading();
                this.loading = true;

                this.quantity = isAdd ? this.quantity + 1 : this.quantity - 1;
                this.changeCartDebounce(this, this.quantity)
            },
            changeCartDebounce: _.debounce((_this, isAdd) => _this.changeCartRequest(isAdd), 500),
            async changeCartRequest(quantity) {
                if (this.productCart == null) {
                    await this.cartProduct({
                        product_id: this.product.id,
                        stock_type: this.product.stock.type,
                        quantity: 1
                    })
                } else {
                    await this.cartProduct({
                        product_id: this.product.id,
                        stock_type: this.productCart.stock.type,
                        quantity: quantity,
                        line_id: this.productCart.line_id,
                    })
                }
            },
            async wantMore() {
                await this.cartWantMore({
                    product_id: this.product.id,
                });
            },
            async clearCartMessage() {
                await this.cartMessageClear();
            }
        },
        watch: {
            cartMessage: function (newVal) {
                if (newVal != null) {
                    this.$bvToast.toast(newVal, {
                        variant: "success",
                        toaster: "b-toaster-bottom-right",
                        solid: true,
                        noCloseButton: true
                    });
                }
                this.clearCartMessage();
            },
            cartChanged: function (newVal) {
                if (newVal != null) {
                    this.loading = false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../assets/css/base';

    @-webkit-keyframes
    rotating{from{-webkit-transform:rotate(0deg)}to{-webkit-transform:rotate(360deg)}}
    @keyframes
    rotating{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}

    .product-card {
        width: 100%;
        max-width: 220px;
        height: 270px;
        position: relative;
        border: 1px solid #F1F1F1;
        /*-webkit-box-shadow: 0px 2px 30px rgb(0 0 0 / 4%);
        -moz-box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.04);
        box-shadow: 0px 2px 30px rgb(0 0 0 / 4%);*/
        border-radius: 5px;
        padding: 10px;

        &__quantity {
            color: #36ba5f;
            font-size: 12px;
            margin-top: 3px;

            &--red {
                color: #FF3B30;
            }

            &--orange {
                color: #F78C07;
            }
        }

        &__photo {
            height: 110px;
            overflow: hidden;
            position: relative;
            border-radius: 5px;
            background: no-repeat center;
            background-size: contain;
        }

        &__btn {
            padding-left: 15px;
            padding-right: 15px;
            font-weight: 500;
            width: calc(100% - 10px);
            margin: 5px;
            font-size: 13px;
            height: 32px;
            border-radius: 5px;
            line-height: 22px;

            &--more {

            }

            svg {
                fill: #ffffff;
                margin-top: -2px;
                margin-right: 6px;
            }
        }

        &__price {
            margin-top: 5px;
            display: -webkit-box;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: wrap;
            -webkit-box-flex: 0;
            flex: 0 0 30px;

            &-normal {
                padding: 4px 7px 4px;
                background-color: #f3f3f3;
                font-weight: 700;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                border-radius: 4px;
                position: relative;

                &:after, &:before {
                    content: '';
                    display: block;
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 50% !important;
                    bottom: auto !important;
                    left: auto;
                    right: auto;
                    padding: 0 !important;
                    margin: 0 !important;
                    -webkit-border-radius: 50% !important;
                    -moz-border-radius: 50% !important;
                    border-radius: 50% !important;
                    z-index: 1;
                    background: #ffffff;
                    -webkit-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    -o-transform: translateY(-50%);
                    transform: translateY(-50%);
                }

                &:after {
                    right: -4px;
                }

                &:before {
                    left: -4px;
                }

                &--red {
                    background-color: #FF3C3C;
                    color: #fff;
                }
            }

            &-discount {
                font-size: 14px;
                height: 17px;
                position: relative;
                line-height: 1.43;
                color: #001a34;
                margin-left: 10px;

                &:before {
                    content: "";
                    position: absolute;
                    top: 9px;
                    width: 100%;
                    height: 2px;
                    display: block;
                    background: #FF3C3C;
                    opacity: 0.7;
                    -webkit-transform: rotate(-3deg);
                    transform: rotate(-3deg);
                }
            }
        }

        &__title {
            max-height: 52px;
            font-size: 13px;
            line-height: 1.4;
            color: #001a34;
            word-break: break-word;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            margin-top: 3px;
        }

        &__weight {
            color: #a5a5a5;
            font-size: 12px;
            white-space: nowrap;
        }

        &__link {

            &-area {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 2;
                cursor: pointer;
            }
        }

        &__badges {
            position: absolute;
            left: 0;
            bottom: 5px;
        }

        &__badge {
            color: #ffffff;
            background-color: $tilda;
            font-weight: bold;
            padding: 3px 8px;
            font-size: 14px;
            margin-right: 5px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;

            &--discount {
                background-color: #FF3B30;
            }

            &--hit {
                background-color: #F78C07;
            }
        }

        &__bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 3;
            //box-shadow: 0 0 15px 0 rgba(0, 90, 239, 0.1);
        }

        &__loader {
            position: absolute;
            top: 50%;
            left: 50%;
            line-height: 1;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            pointer-events: none;
            -webkit-transition: visibility 0s ease .2s, opacity .2s ease;
            -o-transition: visibility 0s ease .2s, opacity .2s ease;
            transition: visibility 0s ease .2s, opacity .2s ease;
            color: #3861b3;
        }

        &__spinner:before {
            border-radius: 50%;
            -webkit-animation: rotating .4s linear infinite;
            animation: rotating .4s linear infinite;
            width: 0.875rem;
            height: 0.875rem;
            border: 0.125rem solid;
            border-right-color: transparent;
            border-bottom-color: transparent;
            opacity: .7;
            display: inline-block;
            content: " ";
        }

        &__basket {
            width: 100%;
            height: 32px;
            border-bottom: 2px solid #005aef;
            border-radius: 5px;

            &-minus {
                float: left;
                width: 24px;
                height: 24px;
                margin-top: 3px;
                padding-left: 20px;
                padding-right: 20px;
                background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02IDExaDEydjJINnoiLz48L3N2Zz4=);
                background-repeat: no-repeat;
                background-position-x: 10px;
                cursor: pointer;
            }

            &-plus {
                float: left;
                width: 24px;
                height: 24px;
                margin-top: 3px;
                padding-left: 20px;
                padding-right: 20px;
                background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMyAxMWg1djJoLTV2NWgtMnYtNUg2di0yaDVWNmgydjV6Ii8+PC9zdmc+);
                background-repeat: no-repeat;
                background-position-x: 10px;
                cursor: pointer;
            }

            &-value {
                float: left;
                width: calc(100% - 80px);
                text-align: center;
                color: #000000;
                font-size: 14px;
                line-height: 30px;
            }
        }
    }
</style>